<template>
  <v-container fluid grid-list-xl class="pa-0">
    <SearchHeader v-if="invests && invests.length" :height="100">
      <v-layout>
        <v-flex>
          <v-row>
            <v-col cols="12" md="2" class="mt-1">
              {{ $t("search.labels.freeTextSearch") }}
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="f.search" append-icon="mdi-magnify" :label="$t('common.tables.filter')" :tabIndex="1201" dense hide-details></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              {{ $t("banks.filter.banks") }}
            </v-col>
            <v-col cols="12" md="4">
              <v-select v-model="f.bank" :items="data.banks" label="Banken" clearable :tabIndex="1202" dense hide-details></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-switch v-model="toggleFilterView" class="ma-2" :label="toggleFilterViewLabel" dense hide-details></v-switch>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </SearchHeader>

    <v-expansion-panels v-model="panel" flat>
      <v-expansion-panel>
        <v-expansion-panel-content class="fContent" color="search">
          <v-row>
            <v-col cols="12" md="2" class="mt-1">
              {{ $t("banks.filter.loan") }}
            </v-col>
            <v-col cols="12" md="4">
              <v-range-slider v-model="f.sumRange" :min="data.sum.min" :max="data.sum.max" hide-details class="align-center" :tabIndex="1203"></v-range-slider>
              {{ this.f.sumRange[0] }} € - {{ this.f.sumRange[1] }} €
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              {{ $t("banks.edit.form.rate") }}
            </v-col>
            <v-col cols="12" md="4">
              <v-range-slider
                v-model="f.rateRange"
                :min="data.rate.min"
                :max="data.rate.max"
                hide-details
                class="align-center"
                :tabIndex="1204"
              ></v-range-slider>
              {{ this.f.rateRange[0] }} € - {{ this.f.rateRange[1] }} €
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" class="mt-1">
              {{ $t("banks.filter.duration") }}
            </v-col>
            <v-col cols="12" md="4">
              <v-range-slider
                v-model="f.durationRange"
                :min="data.duration.min"
                :max="data.duration.max"
                hide-details
                class="align-center"
                :tabIndex="1205"
              ></v-range-slider>
              {{ this.f.durationRange[0] }} {{ $t("common.labels.years") }} - {{ this.f.durationRange[1] }} {{ $t("common.labels.years") }}
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              {{ $t("banks.filter.endsIn") }}
            </v-col>
            <v-col cols="12" md="4">
              <v-slider v-model="f.endsInYears" :max="data.duration.max" hide-details class="align-center" :tabIndex="1206"></v-slider>
              {{ this.f.endsInYears }} {{ $t("common.labels.years") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-select v-model="f.project" :items="data.projects" label="Project" clearable :tabIndex="1207"></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-select v-model="f.owner" :items="data.owners" label="Owner" clearable :tabIndex="1208"></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-select v-model="f.seller" :items="data.sellers" label="Seller" clearable :tabIndex="1209"></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-select v-model="f.customer" :items="data.customers" label="Customers" clearable :tabIndex="1210"></v-select>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <br />

    <template v-if="invests && invests.length">
      <v-data-table
        :headers="headers"
        :items="investments"
        @click:row="exp"
        :items-per-page="15"
        :search="f.search"
        :expanded.sync="expanded"
        class="elevation-1 ma-5"
      >
        <template v-slot:item.invend="{ item }">
          {{ item.invend | date }}
          <v-progress-linear v-if="item.state != 'archived'" color="light-blue" height="10" :value="item.progressPercent" striped></v-progress-linear>
        </template>
        <template v-slot:item.sum="{ item }">
          {{ item.sum }} €
          <!--{{ $parseCurrency.formattedValue(132465) }}-->
        </template>
        <template v-slot:item.rate="{ item }">
          {{ item.rate }} €
          <!--{{ $parseCurrency.formattedValue(132465) }}-->
        </template>
        <template v-slot:item.duration="{ item }">
          {{ item.duration }} {{ $t("common.labels.years") }}
          <span v-if="item.state != 'archived'">({{ item.remaining }} {{ $t("banks.filter.remaining") }})</span>
          <!--{{ $parseCurrency.formattedValue(132465) }}-->
        </template>

        <template v-slot:item.actions="{ item }">
          <template v-if="item.state != 'archived'">
            <v-icon v-if="item.tasks.length" color="lime darken-2">mdi-calendar-check</v-icon>
            <v-icon v-if="item.remaining < 2 && !item.tasks.length" color="orange">mdi-alert-outline</v-icon>
            <v-icon v-if="item.overDue" color="primary">mdi-archive-arrow-down-outline</v-icon>
            <v-icon v-if="item.tasks.length || item.remaining < 2 || item.overDue" color="lime darken-2">{{ getInvIcon(item) }}</v-icon>
          </template>
          <v-icon v-else left color="primary">{{ getInvIcon(item) }}</v-icon>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div v-for="(task, j) in item.tasks" :key="j" class="my-2" :class="{ closed: task.state != 'open' }" @click="jumpToTask(task.id)">
              <v-icon color="accent" left>mdi-calendar-check</v-icon> <strong>{{ task.dueDate | date }}</strong> - {{ task.body }} {{ task.state }}
            </div>
            <div style="text-align: right">
              <v-btn text v-if="true || (item.remaining < 2 && !item.tasks.length)" @click="showTaskCreate(item.id)">
                <v-icon left color="orange">mdi-alert-outline</v-icon> Errinerung erstellen
              </v-btn>

              <v-btn text v-if="item.overDue" @click="archivateInvestment(item)">
                <v-icon left color="primary">mdi-archive-arrow-down-outline</v-icon> Archivieren
              </v-btn>
            </div>
          </td>
        </template>

        <template v-slot:no-data>
          {{ $t("common.list.noResults") }}
        </template>
      </v-data-table>
      <Dlg ref="dialogs" :title="$t('investments.delete.title')" :onOk="addTask">
        Danysan is here
        <v-text-field label="Regular" name="txt"></v-text-field>
      </Dlg>

    </template>
    <div v-else>{{ $t("invests.list.noResults") }}</div>

    <v-navigation-drawer v-model="drawer" absolute temporary right app width="75%">
      <TaskCreate :onSuccess="addTask" :onClose="closeTask" ref="taskCreate" />
    </v-navigation-drawer>

    <br />
    <div class="ma-10">Simulate Current Date:{{ currentDate }} <DropDate :onClose="setCurrentDate" /></div>
    <v-expansion-panels v-model="debugpanel" flat>
      <v-expansion-panel>
        <v-expansion-panel-header color="orange">Debug</v-expansion-panel-header>
        <v-expansion-panel-content>
          Filter:
          <pre>{{ filter }}</pre>
          Current Date:
          <pre>{{ currentDate }}</pre>
          Invests:
          <pre>{{ invests }}</pre>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import DropDate from "@/components/_common/dropdate";
import SearchHeader from "@/components/_common/searchheader";
import Dlg from "@/components/_common/dialogs";
import { formatMixin } from "@/helpers/mixins";
import TaskCreate from "../tasks/taskcreate";

var s = require("searchjs");

export default {
  mixins: [formatMixin],
  components: {
    DropDate,
    SearchHeader,
    Dlg,
    TaskCreate,
  },

  data() {
    return {
      expanded: [],
      toggleFilterView: false,
      debugpanel: false,
      filter: ["imowner=true", "", "state=archived"],
      data: {
        banks: [],
        owners: [],
        sellers: [],
        customers: [],
        projects: [],
        sum: {
          min: 0,
          max: 100000000,
        },
        rate: {
          min: 0,
          max: 1000000,
        },
        duration: {
          min: 0,
          max: 1000000,
        },
      },
      f: {
        bank: null,
        owner: null,
        seller: null,
        customer: null,
        project: null,
        search: "",
        sumRange: [0, 100000000],
        rateRange: [0, 1000000],
        durationRange: [0, 1000000],
        endsInYears: 100,
      },
      invests: [],
      currentDate: new Date(),
      headers: [
        { text: "", align: "left", value: "actions" },
        { text: this.$i18n.t("invests.list.bankname"), align: "left", value: "bank.name" },
        { text: this.$i18n.t("invests.list.customer"), align: "left", value: "project.customer.name" },
        { text: this.$i18n.t("invests.list.sum"), align: "left", value: "sum" },
        { text: this.$i18n.t("invests.list.rate"), align: "left", value: "rate" },
        { text: this.$i18n.t("invests.list.duration"), align: "left", value: "duration" },
        { text: this.$i18n.t("invests.list.invend"), align: "left", value: "invend" },
      ],
      drawer: null,
    };
  },

  props: {
    selectedFilter: {
      type: Number,
      default: () => 0,
    },
  },

  computed: {
    investments: function() {
      let iv = this.invests;
      if (this.currentDate) {
        iv = this.invests.map((i) => {
          let currentYear = this.currentDate.getFullYear();
          let endYear = new Date(i.invend).getFullYear();
          i.remaining = endYear - currentYear;
          i.progressPercent = ((i.duration - i.remaining) * 100) / i.duration;
          i.overDue = this.currentDate > new Date(i.invend);
          return i;
        });
      }

      let conditions = {
        //project: {},
        sum: { gte: this.f.sumRange[0], lte: this.f.sumRange[1] },
        rate: { gte: this.f.rateRange[0], lte: this.f.rateRange[1] },
        duration: { gte: this.f.durationRange[0], lte: this.f.durationRange[1] },
        remaining: { lte: this.f.endsInYears },
      };

      if (this.f.bank) conditions.bankId = this.f.bank;
      if (this.f.owner) conditions["project.ownerId"] = this.f.owner;
      if (this.f.seller) conditions["project.sellerId"] = this.f.seller;
      if (this.f.customer) conditions["project.customerId"] = this.f.customer;
      if (this.f.project) conditions["project.id"] = this.f.project;

      return s.matchArray(iv, conditions);
    },
    banks: function() {
      return [];
    },
    panel: function() {
      if (this.toggleFilterView) {
        return 0;
      }
      return null;
    },
    toggleFilterViewLabel: function() {
      if (this.panel == null) {
        return "Erweiterter filter anzeigen";
      }
      return "Erweiterter filter verbergen";
    },
  },

  created() {
    this.loadData();
  },

  watch: {
    filter: function() {
      this.loadData();
    },
    selectedFilter: function() {
      this.loadData();
    },
  },

  methods: {
    setCurrentDate: function(val) {
      console.log(val);
      this.currentDate = new Date(val);
    },
    loadData: function() {
      axios
        .get(`/api/investments?${this.filter[this.selectedFilter] || ""}&format=details`)
        .then((res) => {
          this.invests = res.data;

          this.data.banks = [];
          this.invests.forEach((i) => {
            this.data.banks.push({ value: i.bank.id, text: i.bank.name });
            this.data.owners.push({ value: i.project.owner.id, text: i.project.owner.name });
            if (i.project.seller) this.data.sellers.push({ value: i.project.seller.id, text: i.project.seller.name });
            if (i.project.customer) this.data.customers.push({ value: i.project.customer.id, text: i.project.customer.name });

            this.data.projects.push({ value: i.project.id, text: i.project.name });
          });

          this.data.sum.min = Math.min.apply(
            Math,
            this.invests.map(function(o) {
              return o.sum;
            })
          );
          this.data.sum.max = Math.max.apply(
            Math,
            this.invests.map(function(o) {
              return o.sum;
            })
          );

          this.data.rate.min = Math.min.apply(
            Math,
            this.invests.map(function(o) {
              return o.rate;
            })
          );
          this.data.rate.max = Math.max.apply(
            Math,
            this.invests.map(function(o) {
              return o.rate;
            })
          );

          this.data.duration.min = Math.min.apply(
            Math,
            this.invests.map(function(o) {
              return o.duration;
            })
          );
          this.data.duration.max = Math.max.apply(
            Math,
            this.invests.map(function(o) {
              return o.duration;
            })
          );

          this.f.endsInYears = this.data.duration.max;
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("showSnackMessage", { message: e });
        });
    },
    addTask: function(task) {
      this.$store.dispatch("setSnack", { message: "Task erstelt" });

      let a = this.invests.find((i) => task.investmentId == i.id);
      a.tasks.push(task);

      Vue.set(
        this.invests,
        this.invests.findIndex((i) => task.investmentId == i.id),
        a
      );

      this.expanded = [a];
    },
    closeTask: function() {
      this.drawer = false;
    },
    jumpToTask: function(id) {
      this.$router.push(`/tasks/${id}`);
    },
    archivateInvestment: function(inv) {
      axios
        .post(`/api/investments/${inv.id}`, {
          state: "archived",
        })
        .then(() => {
          this.invests = this.invests.filter((iv) => iv.id != inv.id);
          this.expanded = [];
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("showSnackMessage", { message: e });
        });
    },
    showTaskCreate: function(iId) {
      this.$refs.taskCreate.init(null, iId);
      this.drawer = true;
    },
    getInvIcon: function(inv) {
      if (this.expanded.includes(inv)) {
        return "mdi-chevron-up";
      }
      return "mdi-chevron-down";
    },
    exp: function(item) {
      if (this.expanded.find((i) => i.id == item.id)) {
        this.expanded = [];
      } else {
        if (item.tasks.length || item.remaining < 2 || item.overDue) {
          this.expanded = [item];
        }
      }
    },
  },
};
</script>

<style lang="scss">
.fContent {
  //background-color: silver;
  & > div {
    padding: 15px;
  }
}
.closed {
  text-decoration: Line-through;
}
</style>
